@use 'styles/colors';
@use 'styles/borders';

.table {
    overflow: auto;
    height: 100%;
    width: 100%;

    table {
        table-layout: auto !important;
        border-collapse: separate;
        margin: 0;
        font-size: 14px;

        thead {
            position: sticky;
            top: 0;
            z-index: 20;

            th {
                white-space: nowrap;
                border-style: none;
                background-color: colors.$base-white;
                svg {
                    width: 1.0625rem;
                    margin-left: 0.25rem;
                }
            }
            tr {
                &.border {
                    th {
                        height: 1px;
                        padding: 0;
                        background-color: colors.$base;
                        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }

        td {
            border-style: none !important;
            white-space: pre-wrap;

            &.fixed {
                position: sticky;
                left: 0;
                z-index: 10;

                min-width: 12.5rem;
                max-width: 12.5rem;
                border-right-width: 1px;
                border-right-style: solid !important;
                border-right-color: colors.$base;
            }

            &.sorted {
                background-color: colors.$cool-accent-lightest;
            }
        }

        tr:nth-child(odd) {
            td {
                background-color: colors.$base-lightest;

                &.sorted {
                    background-color: colors.$cool-accent-lighter;
                }
            }
        }

        @extend %thin-bottom;
    }
}
