.phone {
    .usa-label {
        display: block;
    }
    .fields {
        display: flex;
        align-items: baseline;
        gap: 1rem;
        margin-top: -1rem;
    }
}