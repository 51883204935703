@use 'styles/modal.scss';

.modal {
    max-width: 47rem;
}
.content {
    min-height: 182px;
    padding: 1rem;
    display: flex;
    h2.modalHeading {
        margin: 0;
    }
    .message {
        #success-description,
        #success-modal-details {
            margin-bottom: 1rem;
        }
        p {
            margin: 0;
        }
    }
    .success {
        border-left: 8px solid green;
        margin-right: 1.5rem;
    }
    .successIcon {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 2.66rem;
        color: green;
    }
}

.successFooter{
    justify-content: flex-end !important;
    width: auto;
    button {
        margin: 0;
    }
}
