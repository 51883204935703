@use 'styles/colors';
@use 'styles/components';

.multi-select {
    border-width: 1px;
    border-style: solid;
    border-color: colors.$base-dark;
}

.multi-select-selection__indicator {
    height: 1.25rem;
    width: 1.25rem;
    background-repeat: no-repeat;
    background-size: 1.25rem;
}

.multi-select__control {
    // height: unset;
    // min-height: unset !important;

    .multi-select__indicator {
        color: colors.$base-darker !important;

        height: 2rem;
        width: 2rem;
        padding: 0.5rem;
    }

    .multi-select__indicator-separator {
        background-color: colors.$base-lighter !important;
    }

    .multi-select__multi-value {
        background-color: colors.$primary-lighter !important;
        border-radius: 0.125rem !important;
    }

    .multi-select__multi-value__label {
        color: colors.$primary-dark !important;
        font-size: 100% !important;
    }

    .multi-select__multi-value__remove {
        color: colors.$primary-dark !important;
    }

    .multi-select__multi-value__path {
        display: none !important;
    }

    .multi-select__placeholder {
        color: colors.$base-darkest !important;
        margin: 0;
        padding-left: 0.25rem;
    }

    .multi-select__input {
        margin: 0;
        padding-left: 0.25rem;
    }
}

.multi-select__compact {
    @extend %compact;

    .multi-select__control {
        .multi-select__value-container {
            padding: 0 !important;

            .multi-select__input-container {
                padding: 0;
                margin: 0;

                input {
                    height: unset;
                }
            }
        }
    }
}
