@use 'styles/colors';

.overlay {
    --modal-min-width: 17.1875rem;
}

.gear {
    fill: currentColor;

    width: 1.75rem;
    height: 1.75rem;
}
