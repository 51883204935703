@use 'styles/colors';

$arrowWidth: 0.5rem;
$bubbleWidth: calc($arrowWidth + 100%);

[data-tooltip-position][aria-label] {
    position: relative;

    &::before {
        content: attr(aria-label);
        pointer-events: none;
        white-space: pre;
        position: absolute;
        color: colors.$base-lightest;
        background: colors.$base-darkest;
        padding: 0.5rem;
        border-radius: 0.25rem;
        opacity: 0;
        visibility: hidden;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.3;
        z-index: 1000;
    }

    &::after {
        content: '';
        pointer-events: none;
        width: 0;
        height: 0;
        position: absolute;
        border-color: colors.$base-darkest;
        border-width: 0;
        border-style: solid;
        opacity: 0;
        visibility: hidden;
    }

    &:hover {
        &::before,
        &::after {
            visibility: visible;
            opacity: 1;
            transition-property: opacity;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
            transition-delay: 0s;
        }
    }
}

@mixin bubble($position, $offset: 'none') {
    @if $position == 'top' or $position == 'bottom' {
        #{$position}: $bubbleWidth;
        transform: translateX(50%);

        @if $offset == 'left' {
            right: 100%;
        } @else if $offset == 'right' {
            right: 0;
        } @else {
            right: 50%;
        }
    } @else {
        #{$position}: calc(1rem + 100%);
        transform: translateY(50%);
        bottom: 50%;
    }
}

@mixin vertical-arrow {
    border-right-width: $arrowWidth;
    border-right-color: colors.$clear;
    border-left-width: $arrowWidth;
    border-left-color: colors.$clear;
    right: 50%;
    transform: translateX(50%);
}

@mixin horizontal-arrow {
    border-top-width: $arrowWidth;
    border-top-color: colors.$clear;
    border-bottom-width: $arrowWidth;
    border-bottom-color: colors.$clear;
    bottom: 50%;
    transform: translateY(50%);
}

[data-tooltip-position='top'][aria-label] {
    &:not([data-tooltip-offset])::before {
        @include bubble(bottom);
    }

    &[data-tooltip-offset='left'] {
        &::before {
            @include bubble(bottom, left);
        }
    }

    &[data-tooltip-offset='right'] {
        &::before {
            @include bubble(bottom, right);
        }
    }

    &::after {
        border-top-width: $arrowWidth;
        bottom: 100%;
        @include vertical-arrow();
    }
}

[data-tooltip-position='bottom'][aria-label] {
    &::before {
        @include bubble(top);
    }

    &::after {
        border-bottom-width: $arrowWidth;
        top: 100%;
        @include vertical-arrow();
    }
}

[data-tooltip-position='left'][aria-label] {
    &::before {
        @include bubble(right);
    }

    &::after {
        border-left-width: $arrowWidth;
        right: $bubbleWidth;
        @include horizontal-arrow();
    }
}

[data-tooltip-position='right'][aria-label] {
    &::before {
        @include bubble(left);
    }

    &::after {
        border-right-width: $arrowWidth;
        left: $bubbleWidth;
        @include horizontal-arrow();
    }
}
