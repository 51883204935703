@use 'colors.scss';

@mixin bordered($which: 'border', $color: colors.$base-lighter) {
    #{$which}: 1px solid $color;
}

@mixin rounded($side: '') {
    @if ($side == 'top') {
        border-radius: 0.3125rem 0.3125rem 0rem 0rem;
    } @else if ($side == 'bottom') {
        border-radius: 0rem 0rem 0.3125rem 0.3125rem;
    } @else {
        border-radius: 0.3125rem;
    }
}

%thin {
    @include bordered();
}

%thin-top {
    @include bordered('border-top');
}

%thin-bottom {
    @include bordered('border-bottom');
}
