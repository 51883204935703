@use 'styles/borders';
@use 'styles/colors';

.horizontalInput {
    display: flex;
    padding: 0.5rem 1.5rem;
    align-items: center;
    gap: 2rem;
    min-height: 3rem;
    @extend %thin-bottom;

    label {
        width: 13rem;
        color: colors.$base-darkest;
        font-size: 0.875rem;
        font-weight: 700;
    }

    .verticalWrapper {
        width: 20rem;
    }
}
